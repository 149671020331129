.admin-item {
    width: 300px;
    padding: 20px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 80px;
    align-items: center;
    text-align: center;
    border: 1px dashed #222222;
    border-radius: 15px;
    cursor: pointer;
    transition: .3s;

    &:hover {
        transform: scale(102%);
        border: 1px dashed #808080;
        transition: .3s;
    }

    h3 {
        margin-bottom: 20px;
    }

    p {
        color: #bababa;
    }

    img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 20px;
    }
}