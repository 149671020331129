.gdps-stats-item {
    padding: 20px;
    width: 300px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 60px;
    border: 1px dashed #222222;
    cursor: pointer;
    border-radius: 15px;
    transition: .3s;

    &:hover {
        transform: scale(102%);
        border: 1px dashed #808080;
        transition: .3s;
    }
}