* {
    padding: 0;
    margin: 0;
    font-family: Inter, sans-serif;
    box-sizing: border-box;
    color: #fff;
    user-select: none;
}

body, html {
    overflow: auto;
    overflow-x: hidden;
}

body {
    background-color: #000;
}

li {
    list-style: none;
}

h1 {
    font-size: 68px;
}

h2 {
    font-size: 54px;
    color: #ccc;
}

p {
    color: #bababa;
}

a {
    text-decoration: none;
}

input, button, textarea {
    outline: none;
    background: none;
    border: none;
}

*::-webkit-scrollbar {
    width: 6px;
}
  
*::-webkit-scrollbar-track {
    background: #f0f0f0;
}
  
*::-webkit-scrollbar-thumb {
    background: #606060;
    border-radius: 10px;
}
  
*::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

main {
    min-height: 100%;
    flex: 1 1 auto;
}

.container {
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1300px;
}