.level {
    display: flex;
    align-items: center;
    padding: 20px;
    border: 1px solid #222222;
    border-radius: 15px;
    cursor: pointer;
    margin-bottom: 60px;
    transition: .3s transform;

    &:hover {
        transform: scale(102%);
    }

    img {
        margin-right: 30px;
        width: 160px;
        height: 90px;
        border-radius: 7px;
    }
}

@media(max-width:768px) {
    .level {
        font-size: 14px;

        img {
            width: 150px;
            height: 90px;
            margin-right: 20px;
        }
    }
}