footer {
    border-top: 1px solid #222222;
}

.footer-body {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 40px;
}

.footer-columns {
    display: flex;
}

.footer-column {
    margin-right: 30px;

    h3 {
        color: #fff;
    }
    
    a {
        text-decoration: none;
        color: #bababa;
        transition: .3s color;

        &:hover {
            color: #fff;
            text-decoration: underline;
        }
    }
}

.copyright h3 {
    color: #fff;
}

@media(max-width:768px) {
    .footer-body {
        flex-direction: column;
    }

    .copyright {
        margin-top: 20px;
    }
}