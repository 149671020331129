header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.95;
    border-bottom: 1px solid #242424;
    z-index: 2;

    * {
        font-size: 16px;
    }
}

.header-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
}

.logo {
    a {
        display: flex;
        align-items: center;

        img {
            margin-right: 10px;
            height: 40px;
            width: 40px;
        }
    }
}

.header-nav {
    display: flex;
    align-items: center;

    .header-list {
        display: flex;
        
        li {
            margin-left: 20px;
    
            a {
                color: #bababa;
                transition: .3s color;
    
                &:hover {
                    color: #ffffff;
                }
            }
        }
    }

    button {
        margin-left: 20px;
        padding: 10px;
        cursor: pointer;
        border: 1px solid #fff;
        border-radius: 7px;
        transition: .3s;

        &:hover {
            background-color: #fff;
            color: #000;
            transition: .3s;
        }
    }
}

@media(max-width:768px) {
    .hamburger {
        display: block;
        position: relative;
        width: 30px;
        height: 20px;
        cursor: pointer;
        z-index: 4;
        margin-left: 20px;

        &::before, &::after {
            content: "";
            background-color: #fff;
            position: absolute;
            width: 100%;
            height: 2px;
            left: 0;
            transition: .5s;
        }

        &::before {
            top: 0;
        }

        &::after {
            bottom: 0;
        }

        span {
            position: absolute;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: #fff;
            top: 9px;
            transition: .5s;
        }

        &.active::before {
            transform: rotate(45deg);
            top: 9px;
        }

        &.active::after {
            transform: rotate(-45deg);
            top: 9px;
        }

        &.active span {
            transform: scale(0);
        }
    }

    .header-nav {
        position: fixed;
        right: -100vw;
        top: 0;
        background-color: #000000;
        height: 100vh;
        width: 250px;
        display: block;
        border-left: #222222 solid 3px;
        z-index: 3;
        transition: .3s right;

        &.active {
            right: 0;
        }

        .header-list {
            position: absolute;
            flex-direction: column;
            top: 80px;
            left: 20px;
            right: 20px;
            margin-left: 0;
        }

        .header-list li {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 10px;

            a {
                border-radius: 7px;
                padding: 10px;
                display: block;
                width: 100%;
                color: #fff;
                transition: .3s background-color;

                &:hover {
                    background-color: #222222;
                }
            }
        }

        button {
            position: absolute;
            bottom: 20px;
            right: 20px;
            left: 0;
            text-align: center;
        }
    }
}