.fscreen {
    position: relative;
}

.offer {
    margin-top: 300px;
    text-align: center;
    position: relative;

    h1 {
        color: #ccc;
        margin-bottom: 60px;
    }

    p {
        margin: 0 auto;
        max-width: 900px;
        font-size: 24px;
        color: #919191;
    }

    .offer-btns {
        display: flex;
        justify-content: center;
        margin-top: 60px;

        .offer-ds-btn, .offer-dwnl-btn {
            padding: 10px;
            padding-left: 20px;
            padding-right: 20px;
            border-radius: 7px;
            cursor: pointer;
            font-size: 18px;
        }

        .offer-dwnl-btn {
            margin-right: 20px;
            background-color: #fff;
            color: #000;
            transition: .3s background-color;

            &:hover {
                background-color: #bababa;
            }
        }

        .offer-ds-btn {
            border: 1px solid #fff;
            transition: .3s;

            &:hover {
                background-color: #fff;
                color: #000;
                transition: .3s;
            }
        }
    }
}

.weare {
    margin-top: 300px;
    padding-bottom: 300px;

    .weare-text {
        display: flex;
        align-items: flex-end;
        margin-bottom: 60px;

        p {
            font-size: 21px;
            margin-bottom: 3px;
            margin-left: 10px;
            color: #565656;
        }
    }

    .weare-list {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
}

.about-transition-top {
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
}

.about {
    width: 100%;
    background-color: #1a1a1a;

    h2, p {
        margin-bottom: 20px;
    }

    h2 {
        font-size: 32px;
    }

    p {
        font-size: 16px;
    }

    .about-content {
        display: flex;
        align-items: center;
        padding-top: 160px;

        .about-content-img {
            position: relative;
            width: 500px;
            height: 300px;
            background-color: #fff;
            border-radius: 15px;
            margin-right: 60px;

            &::before {
                content: '';
                background-color: #ececec;
                border-top-right-radius: 30px;
                border-bottom-left-radius: 15px;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 30%;
                height: 50px;
            }

            &::after {
                content: '';
                background-color: #ececec;
                border-top-right-radius: 15px;
                border-bottom-left-radius: 30px;
                position: absolute;
                top: 0;
                right: 0;
                width: 80%;
                height: 80px;
            }
        }

        .about-content-text {
            width: 600px;
        }
    }

    .discord {
        display: flex;
        justify-content: space-between;
        margin-top: 200px;
        padding-bottom: 100px;
        align-items: center;

        .discord-img {
            max-width: 500px;
            margin-left: 60px;

            iframe {
                width: 100%;
                border-radius: 15px;
            }
        }

        .discord-text {
            max-width: 600px;

            button {
                margin-right: 20px;
                background-color: #fff;
                color: #000;
                padding: 10px;
                padding-left: 20px;
                padding-right: 20px;
                border-radius: 7px;
                font-size: 16px;
                cursor: pointer;
                transition: .3s background-color;

                &:hover {
                    background-color: #bababa;
                }
            }
        }
    }
}

.download-transition-top {
    margin-bottom: 200px;
}

.admins {
    .admins-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

.gdps-stats-content {
    margin-bottom: 300px;
    text-align: center;

    h2 {
        margin-bottom: 60px;
    }
    
    .gdps-stats-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

.download {
    margin-top: 300px;
    margin-bottom: 200px;
    display: flex;
    align-items: center;

    .download-img {
        max-width: 500px;
        margin-right: 60px;

        img {
            width: 100%;
            border-radius: 15px;
            filter: grayscale(1);
        }
    }

    h2 {
        font-size: 32px;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px;
    }

    .dwnl-btns {
        display: flex;
        
        a {
            padding: 10px;
            cursor: pointer;
            border: 1px solid #fff;
            border-radius: 7px;
            transition: .3s;
    
            &:hover {
                background-color: #fff;
                color: #000;
                transition: .3s;
            }

            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
}

@media(max-width:768px) {
    .offer {
        margin-top: 160px;
    }
}

@media(max-width:1100px) {
    .about {
        .about-content {
            .about-content-img {
                display: none;
            }
        }
    
        .discord {
            flex-direction: column;
    
            .discord-img {
                margin-left: 0;
                margin-top: 60px;
            }
        }
    }

    .download {
        flex-direction: column;
        text-align: center;
    
        .download-img {
            max-width: 500px;
            margin-right: 0;
            margin-bottom: 60px;
    
            img {
                width: 100%;
                border-radius: 15px;
                filter: grayscale(1);
            }
        }
    
        h2 {
            font-size: 32px;
            margin-bottom: 20px;
        }
    
        p {
            margin-bottom: 20px;
        }
    
        .dwnl-btns {
            display: flex;
            justify-content: center;
            
            a {
                padding: 10px;
                cursor: pointer;
                border: 1px solid #fff;
                border-radius: 7px;
                transition: .3s;
        
                &:hover {
                    background-color: #fff;
                    color: #000;
                    transition: .3s;
                }
    
                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }
}