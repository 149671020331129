.weare-item {
    display: flex;
    align-items: center;
    width: 300px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 60px;

    img {
        margin-right: 20px;
    }
}