.records-window {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    opacity: 99%;
    padding: 20px;
    border: none;
    z-index: 5;

    .records-window-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 20px;
        background-color: #222222;
        z-index: 5;
        border-radius: 15px;
        max-width: 500px;
        width: 100%;
        max-height: 90vh;
        overflow-y: auto;
    }

    h3 {
        text-align: center;
        color: #fff;
    }

    p {
        text-align: center;
    }

    button {
        text-align: right;
        width: 100%;
        cursor: pointer;
        margin-bottom: 20px;
        color: #dddddd;
        transition: .3s color;

        &:hover {
            text-decoration: underline;
            color: #fff;
        }
    }

    .records {
        margin-top: 20px;
    }

    a {
        color: #dddddd;
        transition: .3s color;

        &:hover {
            text-decoration: underline;
            color: #fff;
        }
    }
}